(function() {
  var LoadMore = require('load-more');

  var containers = document.querySelectorAll('[data-load-more]');

  if (containers.length) {
    var l = new LoadMore({
      container: '[data-load-more]',
      button: '[data-load-more-button]',
      link: '[data-load-more-link]',
      remove: '#load-more-pagination'
    });
  }

})();
